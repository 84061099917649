import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Image from "next/image";
import contact from "../../../assets/landing/contact.png";
import style from "./contact.module.css";
import InArticleAds from "../../GoogleAdsense/InArticleAds";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={3} ref={ref} variant="filled" {...props} />;
});

const Contact = ({ adsId }) => {
  //ads Slot
  let slot = 9242238657;

  const {
    register,
    formState: { errors },
  } = useForm();

  //Alert for Message
  const [openErr, setOpenErr] = React.useState(false);
  const [openSuc, setOpenSuc] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState("");

  const handleCloseErr = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErr(false);
  };

  const handleCloseSuc = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuc(false);
  };

  //Email js send Mail Function
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_allo74h",
        "template_130lpns",
        form.current,
        "bdmQMPkfd09tJgX0o"
      )
      .then(
        (result) => {
          setStatusMessage("Thanks for submitting the form");
          setOpenSuc(true);
        },
        (error) => {
          setStatusMessage(error.text);
          setOpenErr(true);
        }
      );
    e.target.reset();
  };

  return (
    <Container>
      <Row
        style={{
          backgroundColor: "#28336F",
        }}
        className={`${style.radius} my-5 p-3 flex-wrap justify-content-center`}
      >
        <div className="text-center">
          <h3 className="fw-bold text-white">Contact </h3>
        </div>
        <Col md={5} className="text-white pt-4">
          <form className="d-flex flex-column" ref={form} onSubmit={sendEmail}>
            <TextField
              required
              className={`${style.filedColor} rounded`}
              id="fullName"
              label="Full Name"
              {...register("fullName", { required: "Full name requried" })}
              error={Boolean(errors.fullName)}
              variant="filled"
              type="text"
              name="user_name"
            />
            <TextField
              required
              className={`${style.filedColor} rounded mt-2`}
              id="email"
              label="Email Address"
              {...register("email", { required: "email requried" })}
              error={Boolean(errors.email)}
              variant="filled"
              type="email"
              name="user_email"
            />

            <TextField
              required
              className={`${style.filedColor} rounded mt-2`}
              id="phone"
              label="Phone No"
              {...register("phone", { required: "phone requried" })}
              error={Boolean(errors.phone)}
              variant="filled"
              type="tel"
              name="phone"
            />

            <TextField
              className={`${style.filedColor} rounded mt-2`}
              required
              id="message"
              label="Message"
              multiline
              rows={4}
              {...register("message", { required: "message requried" })}
              error={Boolean(errors.message)}
              variant="filled"
              name="message"
            />
            <div className="text-center">
              <Button
                className="mt-2"
                variant="contained"
                endIcon={<SendIcon />}
                type="submit"
              >
                Send
              </Button>
            </div>
          </form>
        </Col>
        <Col md={7}>
          <Image
            width="100%"
            height="55%"
            layout="responsive"
            src={contact}
            alt="contact us"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <InArticleAds adsId={adsId} slot={slot} />
        </Col>
      </Row>
      <Snackbar open={openErr} autoHideDuration={5000} onClose={handleCloseErr}>
        <Alert onClose={handleCloseErr} severity="error" sx={{ width: "100%" }}>
          {statusMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openSuc} autoHideDuration={5000} onClose={handleCloseSuc}>
        <Alert onClose={handleCloseSuc} severity="info" sx={{ width: "100%" }}>
          {statusMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Contact;
