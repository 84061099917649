import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Typewriter from "typewriter-effect";
import { Container, Button } from "react-bootstrap";
import style from "./Project.module.css";
import banner from "../../../assets/banner/banner-4.png";
import Image from "next/image";
import Link from "next/link";
import InArticleAds from "../../GoogleAdsense/InArticleAds";

export default function Projects({ adsId }) {
  let slot = 8630513497;
  return (
    <Container className="my-5">
      <div className={`${style.banner_img} mb-2 p-2`}>
        <div>
          <h2 className="fw-bold text-white">UPCOMING PROJECTS</h2>
          <span className="fw-bold" style={{ color: "orange" }}>
            We develop these project for move forwarding
            <Typewriter
              options={{
                strings: [
                  "<span style='color: orange;'> our country, the world, and its people.</span>",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </span>
        </div>

        <Image
          height={180}
          width={290}
          src={banner}
          alt="We develop these project for move forwarding"
        />
      </div>
      <Box>
        <InArticleAds adsId={adsId} slot={slot} />
        <ImageList variant="standard" cols={2} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img} className={`${style.singleImg}`}>
              <img
                className={style.zoom}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                title="research rider"
              />

              <ImageListItemBar
                className={style.bg_color}
                sx={{ fontWeight: "600" }}
                title={item.title}
                subtitle={<small>{item.author}</small>}
                position="top"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <div className="text-center mt-3">
        <Link href="/ictsolution">
          <Button
            className={`${style.button_hover} ${style.color}`}
            variant="light"
          >
            Explore Projects
          </Button>
        </Link>
      </div>
    </Container>
  );
}

const itemData = [
  {
    img: "https://www.templateshub.net/uploads/homethumb.jpg",
    title: "VIP BARI",
    author:
      "vipbari.com is real properties and construction related item vending site",
  },
  {
    img: "https://images.pexels.com/photos/11679513/pexels-photo-11679513.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "LIVE AGRO",
    author:
      "The purpose has always been to provide people with good quality meat, dairy and other agro-products",
  },
  {
    img: "https://img.freepik.com/free-photo/sack-rice-seed-with-white-rice-small-glass-bowl-rice-plant_1150-35747.jpg?w=1800&t=st=1670843338~exp=1670843938~hmac=18568eea79b56f27abcbb0518e75e567d9ffaa28e98cc122ed5c09ce1ab9c324",
    title: "RICE BANGLA",
    author:
      "Sustainable Rice Platform how the Sustainable Rice Platform is transforming the global rice sector for a healthy, inclusive and sustainable future.",
  },
  {
    img: "https://s3.envato.com/files/313691292/Previews/14_Careers.jpg",
    title: "RESEARCH LIVE",
    author:
      "Research live provide your with the possibility to conduct scientific experiments in an online environment.",
  },
  {
    img: "https://colorlib.com/wp/wp-content/uploads/sites/2/CopyPress-1-1024x745.png",
    title: "AARON BANGLA",
    author: "Darren Richardson",
  },
  {
    img: "https://149842030.v2.pressablecdn.com/wp-content/uploads/2019/08/bag-online-store-website-template.png",
    title: "LIYA COLLECTION",
    author: "The Most Loved & Top Pioneer Fashion Brand of Bangladesh",
  },

  {
    img: "https://i.ibb.co/ykhL6Zg/A-Knowladge-Hunter.jpg",
    title: "A KNOWLEDGE HUNTER",
    author:
      "Exploring local knowledge and its role in building community resilience to environmental uncertainty",
  },
  {
    img: "https://i.ibb.co/zrMjwyr/new-song-mix.png",
    title: "New Song Mix",
    author: "This is entertainment community platform",
  },
];
