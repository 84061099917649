import React from "react";

const InArticleAds = ({ adsId, slot }) => {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        overflow: "hidden",
        minWidth: "320px",
        margin: "auto",
      }}
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-layout="in-article"
        data-ad-client={adsId}
        data-ad-slot={slot}
        data-ad-format="fluid"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default InArticleAds;
