import React from "react";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import GitHubIcon from "@mui/icons-material/GitHub";
import Image from "next/image";
import logo from "../../../assets/landing/Banner-logo.svg";
import { Col, Container, Row } from "react-bootstrap";
import Link from "next/link";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ssl from "../../../assets/landing/ssl3.png";
import style from "./Footer.module.css";

const Footer = () => {
  return (
    <Container fluid>
      <footer className="pt-4 rounded-3">
        <Row
          className="d-flex justify-content-center align-items-center py-2"
          style={{ backgroundColor: "#0610fc" }}
        >
          <Col md={3} className="pt-3 text-center">
            <Image
              src={logo}
              width={250}
              height={120}
              alt="Engaging in Research Rider"
              loading="eager"
              priority
            />

            <h5 className="text-white py-2">Grow up Being a Researcher</h5>
          </Col>

          <Col md={6}>
            <div className="footer-copyright text-center text-white pt-2">
              <div>
                <h4>Get In Touch</h4>
              </div>
              <div>
                <address>
                  <span className="d-block text-center">
                    <LocalPhoneIcon />
                    <a href="tel:+880 1729-914461" style={{ color: "white" }}>
                      +880 1729-914461
                    </a>{" "}
                    | <EmailOutlinedIcon />
                    <a
                      href="mailto:info@researchrider.com"
                      style={{ color: "white" }}
                    >
                      {" "}
                      info@researchrider.com
                    </a>
                  </span>
                  <span>
                    <FmdGoodOutlinedIcon />
                    Rupnagar R/A, Road-04, House-26/1, <br /> Mirpur-02, Dhaka,
                    Bangladesh
                  </span>
                </address>
              </div>
              <div className="d-flex justify-content-evenly pt-3">
                <Link href="/our/about">
                  <a className="text-white">About</a>
                </Link>
                <Link href="/ictsolution">
                  <a className="text-white">Services</a>
                </Link>
                <Link href="/our/privacy-policy">
                  <a className="text-white">Privacy Policy</a>
                </Link>
                <Link href="/our/CookeyPolicy">
                  <a className="text-white">Cookie Policy</a>
                </Link>
              </div>
              <div className="py-2 text-center">
                <FacebookRoundedIcon /> <LinkedInIcon /> <TwitterIcon />{" "}
                <GitHubIcon />
              </div>
            </div>
          </Col>

          <Col md={3}>
            <Image
              className={`${style.ssl_img}`}
              src={ssl}
              alt="ssl logo for support bank"
              sizes="(max-width: 768px) 100vw,
                        (max-width: 1200px) 50vw,
                        33vw"
              priority
            />
          </Col>
        </Row>
      </footer>
    </Container>
  );
};

export default Footer;
