export const servicesData = [
  {
    img: "https://img.icons8.com/color/144/null/wordpress.png",
    title: "WordPress Development",
    details:
      "We craft your WordPress web design experiences around the goal of increasing engagement. A well-designed and developed website will help your business gain an advantage online.",
  },

  {
    img: "https://cdn-icons-png.flaticon.com/512/2900/2900778.png",
    title: "Web App Development",
    details:
      "Develop robust online applications to suit your business needs and cater to your clients faithfully.",
  },

  {
    img: "https://cdn-icons-png.flaticon.com/512/7067/7067981.png",
    title: "Mobile App Development",
    details:
      "Developing innovative and native mobile apps for Android, iOS, BlackBerry and Windows platforms.",
  },

  {
    img: "https://cdn-icons-png.flaticon.com/512/1162/1162499.png",
    title: "E-Commerce",
    details:
      "We provide ultimate e-commerce solution as well as customizations for your e-commerce business",
  },
  {
    img: "https://img.icons8.com/external-flatart-icons-flat-flatarticons/256/external-digital-marketing-digital-marketing-flatart-icons-flat-flatarticons.png",
    title: "Digital Branding Service",
    details:
      "We offer a complete line of customized advertising options on a user-friendly network of top content websites to optimize campaigns and increase conversion rates.",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/769/769198.png",
    title: "Software Maintenance ",
    details:
      "Our IT support and maintenance services are designed to manage software maintenance proactively to ensure bug-free deployment and make your software strong and secure.",
  },

  {
    img: "https://cdn-icons-png.flaticon.com/512/3607/3607459.png",
    title: "SEO SERVICES",
    details:
      "If you need SEO services for your website then We will help you to rank your website SERP!",
  },

  {
    img: "https://cdn-icons-png.flaticon.com/512/8055/8055576.png ",
    title: "ML & AI",
    details:
      "Machine Learning and Artificial Intelligence solution for your organization by experts hand.",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/5579/5579145.png",
    title: "ERP",
    details:
      "ERP 23 is the all-in-one management software to streamline every process in your organization’s business",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/2888/2888463.png",
    title: "E-Learning",
    details:
      "We develop LMS platforms with Moodle, to create the online learning environment the way you imagined.",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/3169/3169428.png",
    title: "AR VR Solution",
    details:
      "Customized Augmented and Virtual Reality Solutions to bring your visual commerce to life.",
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/2013/2013862.png",
    title: "Video Editing & Animation",
    details:
      "Present your business to the world by creating result driven video content.",
  },
];
