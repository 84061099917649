import * as React from "react";
import { useRouter } from "next/router";
import { Col, Container, Row, Button } from "react-bootstrap";
import { servicesData } from "../../../Data/servicesData";
import { Paper } from "@mui/material";
import Typewriter from "typewriter-effect";
import style from "./services.module.css";
import Image from "next/image";
import banner from "../../../assets/banner/banner-3.png";
import Link from "next/link";

export default function Services() {
  const router = useRouter();
  const ict = router.pathname === "/ictsolution" ? false : true;

  const font = {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "150%",
  };

  const detailFont = {
    fontWeight: "500",
    fontSize: "14px",
  };
  return (
    <Container className="my-4">
      {ict && (
        <Paper className={`${style.banner_img} p-2`}>
          <div>
            <h2 className="fw-bold text-white">ICT SOLUATIONS</h2>
            <span className="fw-bold">
              Which develops software and applications; sets up data center,
              security and
              <Typewriter
                options={{
                  strings: [
                    "<strong style='color: #fff;'>networking systems; and provides ICT consultancy.</strong>",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </div>
          <div className={style.img_size}>
            <Image
              loading="eager"
              src={banner}
              layout="fill"
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              title="Engaging in Research Rider"
              alt="Engaging in Research Rider"
            />
          </div>
        </Paper>
      )}

      <Row xs={1} sm={2} md={2} xl={4} xxl={5} className="g-2 pt-4">
        {servicesData?.map((engage, idx) => (
          <Col key={idx}>
            <Paper
              className={`${style.zoom} text-center p-2 rounded-4 m-2`}
              key={idx}
            >
              <picture>
                <img
                  width={115}
                  height={115}
                  src={engage.img}
                  alt={engage.title}
                  title={engage.title}
                />
              </picture>

              <div>
                <h3 className="my-3" style={font}>
                  {engage.title}
                </h3>
                <p className="m-2" style={detailFont}>
                  {engage.details}
                </p>
              </div>
            </Paper>
          </Col>
        ))}
      </Row>
      {ict && (
        <div className="text-center mt-3">
          <Link href="/ictsolution">
            <Button
              className={`${style.button_hover} ${style.color}`}
              variant="light"
            >
              Explore Services
            </Button>
          </Link>
        </div>
      )}
    </Container>
  );
}
